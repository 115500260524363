import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'tivoli';

  private _whiteList: string[];
  private _tokens: any;

  constructor() {
    this._whiteList = ['localhost', 'bovo.me'];
    this._tokens = new Array();
  }

  async ngOnInit() {
    // await localStorage.setItem(
    //   `accessToken`,
    //   `accessToken localStorage tivoli.studio`
    // );
    // await localStorage.setItem(`idToken`, `idToken localStorage tivoli.studio`);

    this.startPostMessage();
  }

  async startPostMessage() {
    if (!window.top[0]) return;

    const clientUrl: string = window.top[0].location.ancestorOrigins[0];
    let clientUrlAux: string = clientUrl;
    let clientHost: string;
    let clientProtocol: string;
    let clientPort: string;
    let originTarget: string = ``;

    this._tokens = {
      accessToken: await localStorage.getItem(`accessToken`),
      idToken: await localStorage.getItem(`idToken`),
    };

    clientProtocol = `${clientUrl.includes('https') ? 'https:' : 'http:'}`;
    clientUrlAux = clientUrlAux.replace(`${clientProtocol}//`, ``);

    if (clientUrlAux.split(`:`)[1]) {
      clientHost = clientUrlAux.split(`:`)[0];
      clientPort = clientUrlAux.split(`:`)[1];
    } else {
      clientHost = clientUrlAux;
    }

    console.log(`clientProtocol`, clientProtocol);
    console.log(`clientHost`, clientHost);
    console.log(`clientPort`, clientPort);

    this._whiteList.forEach((item) => {
      if (clientHost.includes(item)) {
        originTarget = `${clientProtocol}//${clientHost}${
          clientHost === 'localhost' ? ':' + clientPort : ''
        }`;

        console.log(`_tokens`, this._tokens);
        console.log(`originTarget`, originTarget);

        window.top.postMessage(JSON.stringify(this._tokens), originTarget);
      }
    });
  }
}
